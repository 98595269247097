<h1>call the Wet</h1>

<h3>App settings</h3>
App version: {{appVersion}}

<h3>Environment settings</h3>
Instance Name: {{instanceName}}
<br>Api url: {{apiUrl}}


<div class="content">
Wersja ósma
</div>