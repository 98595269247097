import { Component } from '@angular/core';
const { version: appVersion } = require('../../package.json')
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'callTheWet';

  public appVersion;
  public instanceName;
  public apiUrl;

  constructor() {
    this.appVersion = appVersion
    this.instanceName = environment.instamce
    this.apiUrl = environment.api
  }

}
